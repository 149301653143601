@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{
    font-size : 100%;
    font-family: "Poppins", sans-serif;
    @apply scroll-smooth;
}

html::-webkit-scrollbar{
    @apply  w-1 rounded-sm bg-gray-50;
}
html::-webkit-scrollbar-thumb{
    @apply absolute w-1 rounded-md bg-slate-300;
}
*, *::after{
    @apply transition-all duration-100 appearance-none outline-none;
}
body{
    @apply max-sm:pt-16
}

.container{
    @apply mx-auto px-4 md:max-w-3xl lg:max-w-6xl 2xl:max-w-7xl;
}